import axios from "axios";

export const GetProductById = async (productId: string) => {
    const API = `/api/v1/product/${productId}`;
    const jwtToken = localStorage.getItem('jwt');
    try {
        return await axios.get(API, {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
        });
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};