import React, { useState } from 'react';
import { Button, IconButton, List, ListItem, ListItemText, Snackbar, Alert } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { saveNewImage } from "../../api/saveImageApi";
import { useNavigate } from "react-router-dom";

const SaveImage = ({ handleSaveNewImage }: { handleSaveNewImage: (paths: string[]) => void }) => {
    const [selectedImgs, setSelectedImgs] = useState<File[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const navigate = useNavigate();

    const handleAddImg = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            setSelectedImgs([...selectedImgs, ...Array.from(files)]);
        }
    };

    const handleRemoveImg = (index: number) => {
        const newSelectedImgs = selectedImgs.filter((_, i) => i !== index);
        setSelectedImgs(newSelectedImgs);
    };

    const handleSaveImg = async () => {
        setLoading(true);
        try {
            const uploadPromises = selectedImgs.map(file => saveNewImage(file));
            const responses = await Promise.all(uploadPromises);
            const paths = responses.map(response => response.data);
            handleSaveNewImage(paths);
            setSelectedImgs([]);
        } catch (error) {
            const errorMessage = (error as any).response?.data?.message || 'Failed to upload files';
            setError(errorMessage);
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleSaveImgAndExit = async () => {
        await handleSaveImg();
        navigate(-1);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <div className="text-end">
            <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                type="file"
                onChange={handleAddImg}
                multiple
            />
            <label htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                    {selectedImgs.length > 0 ? `${selectedImgs.length} images added` : 'Add image'}
                </Button>
            </label>
            <Button
                variant="contained"
                color="primary"
                onClick={handleSaveImg}
                sx={{ marginLeft: '20px' }}
            >
                {loading ? 'Pending...' : 'Save'}
            </Button>

            <Button
                variant="contained"
                color="primary"
                onClick={handleSaveImgAndExit}
                sx={{ marginLeft: '20px' }}
            >
                {loading ? 'Pending...' : 'Save & Exit'}
            </Button>

            <List>
                {selectedImgs.map((img, index) => (
                    <ListItem key={index} secondaryAction={
                        <IconButton onClick={() => handleRemoveImg(index)} aria-label="delete" color="secondary">
                            <DeleteIcon />
                        </IconButton>
                    }>
                        <ListItemText primary={img.name} />
                    </ListItem>
                ))}
            </List>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={600000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity="error"
                    sx={{ width: '100%', textAlign: 'left' }}>
                    {error}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default SaveImage;