import React, { useEffect, useState, useRef } from 'react';
import { getAllOrdersApi } from "../../api/getAllOrdersApi";
import { importOrdersFromBolApi } from "../../api/ImportOrdersFromBolApi";
import LoadingButton from "@mui/lab/LoadingButton";
import { Order } from "../../types/types";

const OrderHistoryPage = () => {
    const [ordersList, setOrdersList] = useState<Order[]>([]);
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const observer = useRef<IntersectionObserver | null>(null);

    useEffect(() => {
        const fetchOrderList = async () => {
            setLoading(true);
            const resp = await getAllOrdersApi(page);
            setOrdersList(prevOrders => [...prevOrders, ...resp.data.content]);
            setLoading(false);
        };

        fetchOrderList();
    }, [page]);

    const loadMoreOrders = () => {
        setPage(prevPage => prevPage + 1);
    };

    const lastOrderElementRef = (node: HTMLLIElement | null) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                loadMoreOrders();
            }
        });
        if (node) observer.current.observe(node);
    };

    const importOrders = async () => {
        await importOrdersFromBolApi();
    };

    return (
        <div>
            <LoadingButton
                size="small"
                onClick={importOrders}
                variant="contained"
                sx={{ marginBottom: '15px' }}
            >
                <span>Import orders</span>
            </LoadingButton>
            <ul>
                {ordersList.map((order: Order, index: number) => {
                    if (ordersList.length === index + 1) {
                        return (
                            <li
                                ref={lastOrderElementRef}
                                key={order.orderId}
                                className="p-4 mb-4 border rounded-lg shadow-sm"
                            >
                                <OrderItem order={order} />
                            </li>
                        );
                    } else {
                        return (
                            <li key={order.orderId} className="p-4 mb-4 border rounded-lg shadow-sm">
                                <OrderItem order={order} />
                            </li>
                        );
                    }
                })}
            </ul>
            {loading && <div>Loading...</div>}
        </div>
    );
};

const OrderItem: React.FC<{ order: Order }> = ({ order }) => (
    <>
        <div className="text-lg font-semibold text-gray-700">
            {order.orderId}
        </div>
        <div className="text-sm text-gray-500">
            {new Date(order.orderPlacedDateTime).toLocaleString()}
        </div>
        <div className="mt-4 bg-gray-50 p-4 rounded-lg shadow-inner w-full">
            <div className="flex justify-between">
                <div className="text-gray-600 space-y-2">
                    <div>
                        <span className="font-medium">First Name:</span> {order.shipmentDetails.firstName}
                    </div>
                    <div>
                        <span className="font-medium">Surname:</span> {order.shipmentDetails.surname}
                    </div>
                    <div>
                        <span className="font-medium">Email:</span> {order.shipmentDetails.email}
                    </div>
                </div>
                <div className="text-gray-600 space-y-2 text-left">
                    <div>
                        <span className="font-medium">City:</span> {order.shipmentDetails.city}
                    </div>
                    <div>
                        <span className="font-medium">Street:</span> {order.shipmentDetails.streetName}
                    </div>
                    <div>
                        <span className="font-medium">Zip Code:</span> {order.shipmentDetails.zipCode}
                    </div>
                    <div>
                        <span className="font-medium">House:</span> {order.shipmentDetails.houseNumber}
                    </div>
                </div>
            </div>
        </div>
    </>
);

export default OrderHistoryPage;