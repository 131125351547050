import React, {ReactNode, useEffect, useState} from 'react';
import { Checkbox, FormControlLabel } from "@mui/material";
import { ProductType } from "../../../../types/types";
import { useStoreContainer } from "../../../../store/store";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../../../../App.css';

const modules = {
    toolbar: [
        [{ 'font': [] }, { 'size': [] }],
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'align': [] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'color': [] }, { 'background': [] }],
        [{ 'script': 'sub'}, { 'script': 'super' }],
        ['blockquote', 'code-block'],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        [{ 'indent': '-1'}, { 'indent': '+1' }],
        [{ 'direction': 'rtl' }],
        ['link', 'image', 'video'],
        ['clean'],
        ['formula'],
    ],
    clipboard: {
        matchVisual: false,
    },
};

const formats = [
    'header', 'font', 'size',
    'bold', 'italic', 'underline', 'strike',
    'blockquote', 'list', 'bullet', 'indent', 'link', 'image', 'video',
    'color', 'background', 'align', 'direction',
    'script', 'formula', 'code-block',
];

const transformString = (str: string): string => {
    if (str === 'priceSupplier') {
        return 'Price from Supplier';
    }

    if (str === 'sku') {
        return str.toUpperCase();
    }

    if (str === 'productId') {
        return 'Product ID';
    }
    return str
        .replace(/([A-Z])/g, ' $1')
        .replace(/^\w/, (c) => c.toUpperCase())
        .trim();
};

const sizeConversion = [
    { usSize: 1, euSize: '33 2/3' },
    { usSize: 1.5, euSize: '34' },
    { usSize: 2, euSize: '34 2/3' },
    { usSize: 2.5, euSize: '35.5' },
    { usSize: 3, euSize: '36' },
    { usSize: 3.5, euSize: '36 2/3' },
    { usSize: 4, euSize: '37 1/3' },
    { usSize: 4.5, euSize: '38' },
    { usSize: 5, euSize: '38 2/3' },
    { usSize: 5.5, euSize: '39 1/3' },
    { usSize: 6, euSize: '40' },
    { usSize: 6.5, euSize: '40 2/3' },
    { usSize: 7, euSize: '41 1/3' },
    { usSize: 7.5, euSize: '42' },
    { usSize: 8, euSize: '42 2/3' },
    { usSize: 8.5, euSize: '43 1/3' },
    { usSize: 9, euSize: '44' },
    { usSize: 9.5, euSize: '44 2/3' },
    { usSize: 10, euSize: '45 1/3' },
    { usSize: 10.5, euSize: '46' },
    { usSize: 11, euSize: '46 2/3' },
    { usSize: 11.5, euSize: '47 1/3' },
    { usSize: 12, euSize: '48' },
    { usSize: 12.5, euSize: '48 2/3' },
    { usSize: 13, euSize: '49 1/3' },
    { usSize: 13.5, euSize: '50' },
    { usSize: 14, euSize: '50 2/3' },
];

type HandleInputChangeType = (field: keyof ProductType) => (event: React.ChangeEvent<HTMLInputElement>) => void;

interface AdditionalDataProps {
    renderTextField: (label: string, field: keyof ProductType, required?: boolean) => ReactNode;
    handleInputChange: HandleInputChangeType;
    setSize: (size:string) => void;
}


const AdditionalData: React.FC<AdditionalDataProps> = ({ renderTextField, handleInputChange, setSize }) => {
    const { updateEditableProduct, editableProduct } = useStoreContainer(state => state);
    const [sizeConverted, setSizeConverted] = useState(false);

    const convertSizeForSupplier = (product: ProductType): ProductType => {
        if (product.supplier.supplierName === 'Werner') {
            const usSize = Number(product.productSize);
            const convertedSize = sizeConversion.find(size => size.usSize === usSize);
            if (convertedSize) {
                return {
                    ...product,
                    productSize: convertedSize.euSize,
                };
            }
        }
        return product;
    };

    useEffect(() => {
        if (editableProduct && !sizeConverted) {
            const updatedProduct = convertSizeForSupplier(editableProduct);
            if (updatedProduct.productSize !== editableProduct.productSize) {
                updateEditableProduct(updatedProduct);
                setSizeConverted(true);
            }
        }
        const cleanedString = editableProduct?.productSize.replace(/\s+/g, '');
        setSize(cleanedString || '')
    }, [editableProduct, sizeConverted, updateEditableProduct]);

    const renderCheckbox = (label: string, field: keyof ProductType) => (
        <div className='flex items-center mb-4 justify-end' key={field}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={!!editableProduct?.[field]}
                        onChange={handleInputChange(field)}
                    />
                }
                label={transformString(label)}
            />
        </div>
    );

    const handleDescriptionChange = (value: string) => {
        if (editableProduct) {
            updateEditableProduct({
                ...editableProduct,
                description: value,
            });
        }
    };

    const filteredFields = Object.keys(editableProduct || {}).filter(
        key => ![
            'dateCreate', 'dateUpdate', 'productImages',
            'passedModeration', 'title', 'description', 'ean',
            'supplierEan', 'sellingPrice', 'deliveryCode',
            'weight', 'width', 'length', 'height', 'sku', 'mainProduct'
        ].includes(key)
    );

    return (
        <div>
            <div className='mb-12'>
                {renderCheckbox('Passed Moderation', 'passedModeration')}

                <h2 className='mb-4'>Description:</h2>
                <ReactQuill
                    value={editableProduct?.description || ''}
                    onChange={handleDescriptionChange}
                    className='w-full text-lg editor'
                    theme="snow"
                    modules={modules}
                    formats={formats}
                />
            </div>
            <div
                className='grid grid-cols-1 gap-4 mb-6 md:grid-cols-2'>
                {filteredFields.map(key => {
                    if (typeof editableProduct?.[key as keyof ProductType] === 'string'
                        || typeof editableProduct?.[key as keyof ProductType] === 'number'
                        || editableProduct?.[key as keyof ProductType] === null) {
                        return renderTextField(transformString(key), key as keyof ProductType);
                    }
                    return null;
                })}
            </div>
        </div>
    );
};

export default AdditionalData;