import { create } from 'zustand';
import { ProductImage, ProductType, Store } from "../types/types";
import { getFilteredProducts } from "../api/productsApi";
import { getAllSupplier } from "../api/supplierApi";
import { newFeedImport } from "../api/feedApi";
import { logsApi } from "../api/logsApi";
import { getAllBrands } from "../api/brandApi";
import { getAllCategory } from "../api/categoryListApi";
import { runUpdateInfoFromBol } from "../bolApi/updateInfoFromBol";
import {getAllFamilyKeyApi} from "../api/getAllFamilyKeyApi";

export const useStoreContainer = create<Store>((set) => ({
    brands: [],
    products: [],
    supplier: [],
    errorType: 0,
    loading: false,
    categoryList: [],
    selectedList: [],
    familyKeyList: [],
    parsingHistory: [],
    productsToShow: 20,
    editableProduct: null,
    paginationTotalPage: 0,
    currentPaginationPage: 0,
    stock: '',
    sortBy: '',
    dateTo: '',
    priceTo: '',
    dateFrom: '',
    familyKey: '',
    priceFrom: '',
    searchValue: '',
    isChangeEan: '',
    isMainProduct:'',
    isExportOnBol: '',
    brandFiltered: '',
    buyBlockFiltered: '',
    supplierFiltered: '',
    descriptionFiltered: '',
    categoryIdFiltered: '',
    totalProductsToShow: '',
    numberOfImagesFiltered: '',
    passedModerationFiltered: '',

    getFilteredProducts: async (
        productQuantity,
        supplierName,
        page,
        brand,
        search,
        passedModeration,
        hasDescription,
        buyBlock,
        priceFrom,
        priceTo,
        categoryId,
        dateFrom,
        dateTo,
        sort,
        numberOfImages,
        familyKey,
        isMainProduct,
        isExportOnBol,
        isChangeEan,
        stock,
    ) => {
        set({ loading: true });
        set({ products: [] });
        try {
            const response = await getFilteredProducts({
                productQuantity,
                supplierName,
                page,
                brand,
                search,
                passedModeration,
                hasDescription,
                buyBlock,
                priceFrom,
                priceTo,
                categoryId,
                dateFrom,
                dateTo,
                sort,
                numberOfImages,
                familyKey,
                isMainProduct,
                isExportOnBol,
                isChangeEan,
                stock
            });
            set({ products: response.content });
            set({ paginationTotalPage: response.totalPages });
            set({ totalProductsToShow: response.totalElements });
        } catch (error: any) {
            set({ errorType: error.response.status });
        } finally {
            set({ loading: false });
        }
    },

    getSupplier: async () => {
        try {
            const suppliers = await getAllSupplier();
            set({ supplier: [{ supplierId: 0, supplierName: "All", supplierUrlFeed: '', inProgress: false }, ...suppliers] });
        } catch (error: any) {
            set({ errorType: error.response.status });
        }
    },

    getHistory: async (quantity) => {
        try {
            const history = await logsApi(quantity);
            set({ parsingHistory: history.content });
        } catch (error: any) {
            set({ errorType: error.response.status });
        }
    },

    updateInfoFromBol: async () => {
        try {
            await runUpdateInfoFromBol();
        } catch (error: any) {
            set({ errorType: error.response.status });
        }
    },

    getBrand: async (supplierName) => {
        try {
            const brands = await getAllBrands(supplierName);
            set({ brands: [...brands.data] });
        } catch (error: any) {
            set({ errorType: error.response.status });
        }
    },

    getAllCategory: async () => {
        try {
            const category = await getAllCategory();
            set({ categoryList: category.data });
        } catch (error: any) {
            set({ errorType: error.response.status });
        }
    },

    getKeys: async () => {
        try {
            const family = await getAllFamilyKeyApi();
            set({ familyKeyList: family.data });
        } catch (error: any) {
            set({ errorType: error.response.status });
        }
    },

    runImport: async (supplier) => {
        try {
            return await newFeedImport(supplier);
        } catch (error: any) {
            set({ errorType: error.response.status });
            throw error;
        }
    },

    changePaginationPage: (page) => {
        set({ currentPaginationPage: page });
    },

    changeProductsToShow: (value) => {
        set({ productsToShow: value });
        set({ currentPaginationPage: 0 });
    },

    changeBrandToShow: (value) => {
        set({ brandFiltered: value });
        set({ currentPaginationPage: 0 });
    },

    changeSupplierToShow: (value) => {
        set({ supplierFiltered: value });
        set({ currentPaginationPage: 0 });
    },

    changeSearchValue: (value) => {
        set({ searchValue: value });
        set({ currentPaginationPage: 0 });
    },

    changeDescriptionToShow: (value) => {
        set({ descriptionFiltered: value });
        set({ currentPaginationPage: 0 });
    },

    changePassedModerationToShow: (value) => {
        set({ passedModerationFiltered: value });
        set({ currentPaginationPage: 0 });
    },

    changeBuyBlockToShow: (value) => {
        set({ buyBlockFiltered: value });
        set({ currentPaginationPage: 0 });
    },

    changeIsMainProductToShow: (value) => {
        set({ isMainProduct: value });
        set({ currentPaginationPage: 0 });
    },

    changeIsExportOnBol: (value) => {
        set({ isExportOnBol: value });
        set({ currentPaginationPage: 0 });
    },

    changeIsChangeEan: (value) => {
        set({ isChangeEan: value });
        set({ currentPaginationPage: 0 });
    },

    changeByStock: (value) => {
        set({ stock: value });
        set({ currentPaginationPage: 0 });
    },

    changePrice: (priceFrom, priceTo) => {
        set({ priceFrom });
        set({ priceTo });
        set({ currentPaginationPage: 0 });
    },

    changeCatToShow: (value) => {
        set({ categoryIdFiltered: value });
        set({ currentPaginationPage: 0 });
    },

    changeDateToShow: (dateTo, dateFrom) => {
        set({ dateFrom });
        set({ dateTo });
        set({ currentPaginationPage: 0 });
    },

    changeSortBy: (value) => {
        set({ sortBy: value });
        set({ currentPaginationPage: 0 });
    },

    changeFamilyKey: (value) => {
        set({ familyKey: value });
        set({ currentPaginationPage: 0 });
    },

    changeNumberOfImagesToShow: (value) => {
        set({ numberOfImagesFiltered: value });
        set({ currentPaginationPage: 0 });
    },

    updateSelectedList: (value: number | number[]) => {
        set(state => ({
            selectedList: Array.isArray(value)
                ? Array.from(new Set([...state.selectedList, ...value]))
                : Array.from(new Set([...state.selectedList, value]))
        }));
    },

    removeSelectedList: (value: number | number[]) => {
        set(state => ({
            selectedList: Array.isArray(value)
                ? state.selectedList.filter(item => !value.includes(item))
                : state.selectedList.filter(item => item !== value)
        }));
    },

    resetSelectedList: () => {
        set({ selectedList: [] });
    },

    resetAllFilters: () => {
        set({
            productsToShow: 20,
            brandFiltered: '',
            supplierFiltered: '',
            searchValue: '',
            descriptionFiltered: '',
            passedModerationFiltered: '',
            buyBlockFiltered: '',
            categoryIdFiltered: '',
            priceFrom: '',
            priceTo: '',
            dateFrom: '',
            dateTo: '',
            sortBy: '',
            selectedList: [],
            currentPaginationPage: 0,
            numberOfImagesFiltered: '',
            familyKey: '',
            isMainProduct:'',
            isChangeEan:'',
        });
    },

    updateEditableProduct: (product: ProductType) => {
        set({ editableProduct: product });
    },

    deleteImageFromEditableProduct: (imgId: number) => {
        set((state) => {
            if (!state?.editableProduct) return state;
            const updatedImages = state.editableProduct.productImages.filter(
                (img) => img.id !== imgId
            );
            return {
                ...state,
                editableProduct: {
                    ...state.editableProduct,
                    productImages: updatedImages,
                },
            };
        });
    },

    addImageToEditableProduct: (newImageObj: ProductImage) => {
        set((state) => {
            if (!state.editableProduct) return state;

            const updatedImages = [...(state.editableProduct.productImages || []), newImageObj];

            return {
                ...state,
                editableProduct: {
                    ...state.editableProduct,
                    productImages: updatedImages,
                },
            };
        });
    },

}));

const state = useStoreContainer.getState();

state.getSupplier();
state.getAllCategory();
state.getKeys();