import * as React from 'react';
import { useState } from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Link, useLocation } from "react-router-dom";
import { Drawer, IconButton, List, ListItem, AppBar, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

const sideBar = [
    { route: '/dashboard', name: "Dashboard" },
    { route: '/products', name: "Products" },
    { route: '/orders', name: "Orders" },
    { route: '/parser', name: "Parser" },
    { route: '/export', name: "Export" },
    { route: '/logs', name: "Logs" },
    { route: '/report', name: "Reports" },
    { route: '/uploadEan', name: "Upload EAN" },
];

export const MainListItems = () => {
    const location = useLocation();
    const [drawerOpen, setDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setDrawerOpen(!drawerOpen);
    };

    return (
        <React.Fragment>
            {/* AppBar with Menu Icon for screens smaller than 800px */}
            <AppBar position="static" sx={{ display: { xs: 'block', md: 'none' }, width: '100%' }}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer}>
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6">
                        Menu
                    </Typography>
                </Toolbar>
            </AppBar>

            {/* Drawer for mobile view (screens 800px and smaller) */}
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer}
                sx={{
                    display: { xs: 'block', md: 'none' },
                    '& .MuiDrawer-paper':
                        { boxSizing: 'border-box',
                            width: 240,
                            marginTop: '130px'
                        },
                }}
            >
                <List>
                    {sideBar.map((item) => {
                        const isActive = location.pathname === item.route;

                        return (
                            <Link to={item.route} style={{ textDecoration: "none" }} key={item.name}>
                                <ListItem button selected={isActive} onClick={toggleDrawer}>
                                    <ListItemText
                                        primary={item.name}
                                        primaryTypographyProps={{
                                            style: isActive ? { color: '#1976d2' } : {}
                                        }}
                                    />
                                </ListItem>
                            </Link>
                        );
                    })}
                </List>
            </Drawer>

            {/* Sidebar for larger screens (greater than 800px) */}
            <List
                sx={{
                    display: { xs: 'none', md: 'flex' },
                    justifyContent: 'space-between',
                    width: '100%',
                    maxWidth: 'none', // Allow it to expand beyond 1200px
                }}>
                {sideBar.map((item) => {
                    const isActive = location.pathname === item.route;

                    return (
                        <Link to={item.route} style={{ textDecoration: "none" }} key={item.name}>
                            <ListItemButton selected={isActive} style={{ backgroundColor: isActive ? "#fff" : "" }}>
                                <ListItemText
                                    primary={item.name}
                                    primaryTypographyProps={{
                                        style: isActive ? { color: '#1976d2' } : {}
                                    }}
                                />
                            </ListItemButton>
                        </Link>
                    );
                })}
            </List>
        </React.Fragment>
    );
};