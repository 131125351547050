import axios from "axios";

export const getAllFamilyKeyApi = async () => {

    const API = `/api/v1/family`;
    const jwtToken = localStorage.getItem('jwt');
    try {
        return await axios.get(API,
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
            });
    } catch (error) {
        console.error('ErrorMessage fetching products:', error);
        throw error;
    }
};