import axios from "axios";

export const newFeedImport = async (supplier: string) => {
    const API = `/api/v1/feed/parse/${supplier}`;
    const jwtToken = localStorage.getItem('jwt');
    try {
        return await axios.post(API, null, {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
        });
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const imagesReimport = async (supplier: string) => {
    const API = `/api/v1/feed/reexport/images/${supplier}`;
    const jwtToken = localStorage.getItem('jwt');
    try {
        return await axios.post(API, null, {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
        });
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};