import React, { useEffect, useState } from 'react';
import {
    TextField,
    Box,
    Button,
    MenuItem,
    Select,
    FormControl,
    Grid,
    Typography,
    Paper,
    Snackbar,
    Alert, CircularProgress, Autocomplete,
} from '@mui/material';
import {Attribute, bolProductModelType, ProductImage} from '../../../types/types';
import { saveBolProductContent } from '../../../bolApi/SaveBolProductContentApi';
import { useNavigate, useParams } from 'react-router-dom';
import { getImagesByProductId } from "../../../api/getImagesByProductIdApi";
import { createNewProductOnBol } from "../../../bolApi/createNewProductOnBolApi";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { getUploadReportApi } from "../../../bolApi/getUploadReportApi";

export const BolProductModelFields: React.FC<{
    bolProductModel: bolProductModelType, images: ProductImage[] | undefined, size: string
}> = (
    { bolProductModel, images, size }
) => {
    const mediaIds = bolProductModel.mediaData?.map(elem => elem.mediaId) || [];
    const [localAttributes, setLocalAttributes] = useState<Attribute[]>(bolProductModel.attributeData);
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false);
    const [errorSnackbarOpen, setErrorSnackbarOpen] = useState<boolean>(false);
    const [errorSnackbarMessage, setErrorSnackbarMessage] = useState('');
    const [errorState, setErrorState] = useState<Record<string, boolean>>({});
    const [filteredOptions, setFilteredOptions] = useState<string[]>(mediaIds);
    const [selectedOptions, setSelectedOptions] = useState<string[]>(new Array(images?.length).fill(''));
    const [isLoading, setIsLoading] = useState(false);


    const navigate = useNavigate();
    const { productId } = useParams<{ productId: string }>();
    const baseURL = `${window.location.protocol}//${window.location.host}`;

    const handleImgChange = (event: any, index: number) => {
        setSelectedOptions(prevSelected => {
            const newSelected = [...prevSelected];
            newSelected[index] = event.target.value;
            return [...newSelected];
        });
    };

    useEffect(() => {
        setLocalAttributes(bolProductModel.attributeData);
    }, [bolProductModel.attributeData]);

    useEffect(() => {
        const updatedAvailableOptions = [...mediaIds];
        const filtered = updatedAvailableOptions.filter(option => !selectedOptions.includes(option));
        setFilteredOptions(filtered);
    }, [selectedOptions, bolProductModel.mediaData]);

    useEffect(() => {
        if (bolProductModel.mediaData && images) {
            const initialSelectedOptions = images.map(image => {
                const matchingMedia = bolProductModel.mediaData?.find(media => media.value && media.value.includes(image.filePath));
                return matchingMedia ? matchingMedia.mediaId : '';
            });
            setSelectedOptions(initialSelectedOptions);
        }
    }, [bolProductModel.mediaData, images]);

    const removeSpaces = (str: string) => str.replace(/\s+/g, '');

    useEffect(() => {
        const attribute = localAttributes.find(attr => attr.attributeName === 'Schoenmaat');

        if (attribute && attribute.valueList) {
            const matchedSize = attribute.valueList.find(value => removeSpaces(value) === removeSpaces(size));

            if (matchedSize) {
                setLocalAttributes(prevAttributes => {
                    const updatedAttributes = prevAttributes.map(attr =>
                        attr.attributeName === 'Schoenmaat' ? { ...attr, value: matchedSize } : attr
                    );
                    return updatedAttributes;
                });
            }
        }
    }, [size, bolProductModel.attributeData]);


    const validateRequiredFields = (): boolean => {
        const missingFields = localAttributes.filter(attr => attr.required && !attr.value);
        if (missingFields.length > 0) {
            const newErrorState = Object.fromEntries(missingFields.map(attr => [attr.attributeId, true]));
            setErrorState(newErrorState);
            setErrorSnackbarMessage('Please fill in all required fields.');
            setErrorSnackbarOpen(true);
            return false;
        }
        return true;
    };

    const handleFieldChange = (field: string, value: string) => {
        setLocalAttributes(prevAttributes =>
            prevAttributes.map(attr => (attr.attributeId === field ? { ...attr, value } : attr))
        );
    };

    const handleSave = async () => {
        if (!productId || !validateRequiredFields()) return;
        try {
            const response = await getImagesByProductId(productId);
            const updatedMediaData = bolProductModel.mediaData?.map((media) => {
                const selectedIndex = selectedOptions.findIndex(option => option === media.mediaId);

                if (selectedIndex !== -1 && response.data[selectedIndex]) {
                    const matchingImage = response.data[selectedIndex];
                    return { ...media, value: `${baseURL}/${matchingImage.filePath}` };
                }

                return media;
            });

            await saveBolProductContent({ ...bolProductModel, mediaData: updatedMediaData, attributeData: localAttributes });
            setErrorState({});
            setSnackbarOpen(true);
        } catch (error) {
            console.error("Failed to save product", error);
            setErrorSnackbarMessage('Failed to save product.');
            setErrorSnackbarOpen(true);
        }
    };

    const handleSaveClose = async () => {
        await handleSave();
        navigate(-1);
    };

    const createNewProductModelOnBol = async () => {
        if (!productId || !validateRequiredFields()) return;

        setIsLoading(true);

        try {
            await getUploadReportApi([], '');
            await createNewProductOnBol([+productId]);

            setSnackbarOpen(true);
        } catch (error) {
            console.error("Failed to create new product on Bol", error);
            setErrorSnackbarMessage('Failed to create new product on Bol.');
            setErrorSnackbarOpen(true);
        } finally {
            setIsLoading(false);
        }
    };

    const renderAttributeField = (attr: Attribute) => {
        const cleanValue = String(attr.value).trim();

        return (
            <Grid item xs={12} key={attr.attributeId}>
                <Typography>
                    {attr.attributeName}
                    {attr.required && <span style={{ color: 'red' }}>*</span>}:
                </Typography>
                {attr.attributeId === 'Description' ? (
                    <ReactQuill
                        value={attr.value || ''}
                        onChange={(value) => handleFieldChange(attr.attributeId, value)}
                        theme="snow"
                        className="w-full text-lg"
                        style={{
                            border: errorState[attr.attributeId] ? '1px solid red' : ''
                        }}
                    />
                ) : attr.valueList ? (
                    <FormControl variant="outlined" size="small" fullWidth error={errorState[attr.attributeId]}>
                        <Select
                            value={cleanValue}
                            onChange={(event) => handleFieldChange(attr.attributeId, event.target.value)}
                            label={attr.attributeName}
                            title={attr.description || ''}
                        >
                            {attr.valueList.map((option, idx) => (
                                <MenuItem key={idx} value={String(option).trim()}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                ) : (
                    <TextField
                        variant="outlined"
                        size="small"
                        value={attr.value || ''}
                        onChange={(event) => handleFieldChange(attr.attributeId, event.target.value)}
                        error={errorState[attr.attributeId]}
                        helperText={errorState[attr.attributeId] ? 'This field is required.' : ''}
                        required={attr.required}
                        fullWidth
                        title={attr.description || ''}
                    />
                )}
            </Grid>
        );
    };

    return (
        <Box>
            <Box mt={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '10px' }}>
                <Button variant="contained" color="primary" onClick={handleSave}>
                    Apply
                </Button>

                <Button variant="contained" onClick={handleSaveClose}>
                    Save & Exit
                </Button>

                <Button
                    variant="contained"
                    color="primary"
                    onClick={createNewProductModelOnBol}
                    disabled={isLoading}
                    startIcon={isLoading && <CircularProgress size={20} />}
                >
                    {isLoading ? 'Creating...' : 'Create New Product on Bol'}
                </Button>
            </Box>

            <Typography variant="h6">Required Fields:</Typography>
            <Paper variant="outlined" style={{ padding: '16px', marginBottom: '20px', borderColor: 'rgba(128, 128, 128, 0.5)', borderRadius: '16px' }}>
                <Grid container spacing={2}>
                    {localAttributes.filter(attr => attr.required).map(renderAttributeField)}
                </Grid>
            </Paper>

            <Typography variant="h6">Optional Fields:</Typography>
            <Paper variant="outlined" style={{ padding: '16px', borderColor: 'rgba(128, 128, 128, 0.5)', borderRadius: '16px' }}>
                <Grid container spacing={2}>
                    {localAttributes.filter(attr => !attr.required).map(renderAttributeField)}
                </Grid>
            </Paper>

            <Typography variant="h6">Images:</Typography>
            <Paper variant="outlined" style={{ padding: '16px', marginBottom: '20px', borderColor: 'rgba(128, 128, 128, 0.5)', borderRadius: '16px' }}>
                <Grid container spacing={2}>
                    {images?.map((image, index) => (
                        <Grid item xs={12} key={image.id}>
                            <Box display="flex" alignItems="center" gap={2}>
                                <img
                                    src={`${baseURL}/${image.filePath}`}
                                    alt={image.name || 'Product Image'}
                                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                />
                                <FormControl variant="outlined" size="small" fullWidth>
                                    <Autocomplete
                                        options={filteredOptions}
                                        value={selectedOptions[index] || null}
                                        onChange={(event, newValue) => handleImgChange({ target: { value: newValue } }, index)}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Image Position" variant="outlined" />
                                        )}
                                    />
                                </FormControl>
                            </Box>
                        </Grid>
                    ))}
                </Grid>
            </Paper>

            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setSnackbarOpen(false)} severity="success" sx={{ width: '100%' }}>
                    Bol product successfully updated!
                </Alert>
            </Snackbar>

            <Snackbar open={errorSnackbarOpen} autoHideDuration={6000} onClose={() => setErrorSnackbarOpen(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
                <Alert onClose={() => setErrorSnackbarOpen(false)} severity="error" sx={{ width: '100%' }}>
                    {errorSnackbarMessage}
                </Alert>
            </Snackbar>
        </Box>
    );
};