import React, { useState, useEffect } from 'react';
import {
    Avatar,
    Button,
    TextField,
    Box,
    Typography,
    Alert
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SendIcon from '@mui/icons-material/Send';
import LoadingButton from '@mui/lab/LoadingButton';
import { signIn } from '../../api/SignInApi';
import { useNavigate } from 'react-router-dom';
import { useStoreContainer } from "../../store/store";

const SignIn: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [errorAlert, setErrorAlert] = useState<boolean>(false);
    const [isLogged, setIsLogged] = useState<boolean>(false);
    const [isAccessTokenExpired, setIsAccessTokenExpired] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [emailError, setEmailError] = useState<boolean>(false);
    const [passwordError, setPasswordError] = useState<boolean>(false);
    const navigate = useNavigate();
    const state = useStoreContainer.getState();

    useEffect(() => {
        setIsLogged(localStorage.getItem('jwt') !== null);

        const expiredAccessToken = localStorage.getItem('expiredAccessToken');
        const currentTime = Date.now();

        if(currentTime > Number(expiredAccessToken)) {
            setIsAccessTokenExpired(true);
            setIsLogged(false);
            localStorage.removeItem('jwt');
            localStorage.removeItem('expiredAccessToken');
        }
    }, []);


    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (!email || !password) {
            setEmailError(!email);
            setPasswordError(!password);
            setErrorMessage('Both fields are required.');
            setErrorAlert(true);
            return;
        }
        setEmailError(false);
        setPasswordError(false);
        setErrorAlert(false);
        setIsLoading(true);

        try {
            const result = await signIn(email, password);
            if (result.data?.accessToken) {
                localStorage.setItem('jwt', result.data.accessToken);
                localStorage.setItem('expiredAccessToken', result.data.expiredAccessToken);
                setIsLogged(true);
                navigate('/dashboard')
                state.getSupplier();
                state.getAllCategory();
                state.getKeys();
            } else {
                setErrorMessage('Access token not found in response');
                setErrorAlert(true);
            }
        } catch (error) {
            setErrorMessage(`Error signing in: ${error}`);
            setErrorAlert(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handelLogOut = () => {
        localStorage.removeItem('jwt');
        localStorage.removeItem('expiredAccessToken');
        setIsLogged(false);
    }

    return (
        <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {!isLogged ? (
                <>
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    {!isAccessTokenExpired && <Typography component="h1" variant="h5">For continue you need sign in first</Typography>}
                    {isAccessTokenExpired && (
                        <Typography component="h1" variant="h5">Your Access Token has been expired</Typography>
                    )}
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="username"
                            label="User Name"
                            autoComplete="username"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            error={emailError}
                            helperText={emailError ? 'Email is required' : ''}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            type="password"
                            id="password"
                            label="Password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            error={passwordError}
                            helperText={passwordError ? 'Password is required' : ''}
                        />
                        {errorAlert && <Alert variant="filled" severity="error">{errorMessage}</Alert>}
                        <LoadingButton
                            type="submit"
                            fullWidth
                            endIcon={<SendIcon />}
                            loading={isLoading}
                            loadingPosition="end"
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </LoadingButton>
                    </Box>
                </>
            ) : (
                <div className="flex flex-col justify-center items-center w-[450px] mx-auto">
                    <Typography variant="h6">You're already logged in.</Typography>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => handelLogOut()}
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign Out
                    </Button>
                </div>
            )}
        </Box>
    );
};

export default SignIn;