import axios from 'axios';
import {bolProductModelType} from "../types/types";

export const saveBolProductContent = async (obj:bolProductModelType) => {
    const API = `/api/v1/bol/data/model/product`;
    const jwtToken = localStorage.getItem('jwt');

    try {
        return await axios.post(API, obj, {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        console.error('Error uploading to BOL API:', error);
        throw error;
    }
};