import axios from "axios";

export const saveNewEanApi = async (file: File): Promise<any> => {
    const API = `/api/v1/ean`;
    const jwtToken = localStorage.getItem('jwt');

    const formData = new FormData();
    formData.append('eanExel', file);

    try {
        return await axios.post(API, formData,{
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
        });
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};