import axios from "axios";

export const getImagesByProductId = async (productId: string) => {
    const API = `/api/v1/image/${productId}`;
    const jwtToken = localStorage.getItem('jwt');
    try {
        return await axios.get(API, {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
        });
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};