import React, { Suspense, lazy, useMemo } from 'react';
import { Navigate, Route, Routes } from "react-router-dom";
import SignIn from "../components/SignIn/SignIn";
import ProductPage from "../components/Products/ProductPage/ProductPage";
import EanUpload from "../components/EanUpload/EanUpload";
import OrderHistoryPage from "../pages/Orders/OrderHistoryPage";

const ProductsPage = lazy(() => import('../pages/ProductsPage/ProductsPage'));
const Parser = lazy(() => import('../components/Parser/Parser'));
const ExportFeed = lazy(() => import('../components/ExportFeed/ExportFeed'));
const LogsPage = lazy(() => import('../pages/LogsPage/LogsPage'));
const ReportPage = lazy(() => import('../pages/ReportPage/ReportPage'));

const AppRouter = () => {
    const routes = useMemo(() => (
        <Routes>
            <Route element={<ProductsPage />} path='/products' />
            <Route element={<ProductPage />} path='/products/:productId' />
            <Route element={<OrderHistoryPage />} path='/orders' />
            <Route element={<div>This page is under construction</div>} path='/dashboard' />
            <Route element={<SignIn/>} path='/login' />
            <Route element={<Parser />} path='/parser' />
            <Route element={<ExportFeed />} path='/export' />
            <Route element={<LogsPage />} path='/logs' />
            <Route element={<ReportPage />} path='/report' />
            <Route element={<EanUpload />} path='/uploadEan' />
            <Route path='*' element={<Navigate to="/dashboard" />} />
        </Routes>
    ), []);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            {routes}
        </Suspense>
    );
};

export default React.memo(AppRouter);
