import React, { ReactNode, useState } from 'react';
import { ProductType } from "../../../../types/types";
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip, IconButton, Snackbar, Alert } from "@mui/material";
import { useStoreContainer } from "../../../../store/store";
import FileCopyIcon from '@mui/icons-material/FileCopy';

const deliveryOptions = [
    "24uurs-23", "24uurs-22", "24uurs-21",
    "24uurs-20", "24uurs-19", "24uurs-18",
    "24uurs-17", "24uurs-16", "24uurs-15",
    "24uurs-14", "24uurs-13", "24uurs-12",
    "1-2d", "2-3d", "3-5d", "4-8d", "1-8d",
    "MijnLeverbelofte", "VVB"
];

interface GeneralDataProps {
    renderTextField: (label: string, field: keyof ProductType, required?: boolean, error?: boolean) => ReactNode;
    errorFields: Partial<Record<keyof ProductType, boolean>>;
    isChangeEan: boolean | undefined;
}

const GeneralData = ({ renderTextField, errorFields, isChangeEan }: GeneralDataProps) => {
    const { updateEditableProduct, editableProduct } = useStoreContainer(state => state);
    const [alert, setAlert] = useState<string | null>(null); // Для уведомления
    const [snackbarOpen, setSnackbarOpen] = useState<boolean>(false); // Открытие/закрытие Snackbar

    const handleSelectChange = (field: keyof ProductType) => (event: SelectChangeEvent<string>) => {
        const value = event.target.value;

        if (editableProduct) {
            updateEditableProduct({
                ...editableProduct,
                [field]: value
            });
        } else {
            console.log('error');
        }
    };

    // Функция копирования в буфер обмена
    const handleCopyToClipboard = (text: string) => {
        if (navigator.clipboard && navigator.clipboard.writeText) {
            navigator.clipboard.writeText(text)
                .then(() => {
                    setAlert(`Copied to clipboard: ${text}`);
                    setSnackbarOpen(true);
                })
                .catch(err => {
                    console.error('Failed to copy: ', err);
                    setAlert('Failed to copy to clipboard');
                    setSnackbarOpen(true);
                });
        } else {
            const textArea = document.createElement("textarea");
            textArea.value = text;
            textArea.style.position = "fixed";
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.width = "2em";
            textArea.style.height = "2em";
            textArea.style.padding = "0";
            textArea.style.border = "none";
            textArea.style.outline = "none";
            textArea.style.boxShadow = "none";
            textArea.style.background = "transparent";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                const successful = document.execCommand('copy');
                if (successful) {
                    setAlert(`Copied to clipboard: ${text}`);
                } else {
                    console.error('Fallback: Unable to copy');
                    setAlert('Fallback: Unable to copy');
                }
            } catch (err) {
                console.error('Fallback: Oops, unable to copy', err);
                setAlert('Fallback: Oops, unable to copy');
            }

            document.body.removeChild(textArea);
        }

        setTimeout(() => setAlert(null), 2000);
    };

    const firstImage = editableProduct?.productImages ? editableProduct.productImages[0] : null;
    const filePath = firstImage ? firstImage.filePath : null;
    const id = firstImage ? firstImage.id : null;
    const baseURL = `${window.location.protocol}//${window.location.host}`;

    const renderSelectField = (label: string, field: keyof ProductType, options: string[], required: boolean = false) => (
        <div className='flex mb-4 items-center' key={field}>
            <InputLabel
                className={`mb-1 w-[150px]`}
                id={`${field}-label`}
            >
                {label}
                {required && <span style={{ color: 'red' }}>*</span>}
            </InputLabel>
            <FormControl variant="outlined" className='w-full'>
                <Select
                    labelId={`${field}-label`}
                    id={field}
                    value={editableProduct?.[field] as string || ''}
                    onChange={handleSelectChange(field)}
                    required={required}
                    sx={{
                        height: '40px',
                        borderColor: errorFields[field] ? 'red' : 'inherit',
                        '& .MuiOutlinedInput-notchedOutline': {
                            borderColor: errorFields[field] ? 'red' : 'inherit'
                        }
                    }}
                >
                    {options.map(option => (
                        <MenuItem key={option} value={option}>{option}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );

    return (
        <>
            {/* Snackbar для уведомления */}
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => setSnackbarOpen(false)}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert severity="success" sx={{ width: '100%' }}>
                    {alert}
                </Alert>
            </Snackbar>

            <div className='flex flex-col-reverse items-center mb-3 md:flex-row md:justify-between'>
                <div className='pl-4 w-full md:w-[500px]'>
                    <div className='flex flex-col gap-6 mb-6'>
                        <div className="flex items-center">
                            {renderTextField(
                                `${isChangeEan ? 'New EAN' : 'EAN'}`,
                                'ean',
                                true,
                                errorFields['ean']
                            )}
                            <Tooltip title="Copy EAN">
                                <IconButton onClick={() => handleCopyToClipboard((editableProduct?.ean || '').toString())}>
                                    <FileCopyIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>

                        {isChangeEan && (
                            <div className="flex items-center">
                                {renderTextField(
                                    `Supplier EAN`,
                                    'supplierEan',
                                    true,
                                    errorFields['supplierEan']
                                )}
                                <Tooltip title="Copy Supplier EAN">
                                    <IconButton onClick={() => handleCopyToClipboard((editableProduct?.supplierEan || '').toString())}>
                                        <FileCopyIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}

                        <div className="flex items-center">
                            {renderTextField(
                                'Selling Price',
                                'sellingPrice',
                                true,
                                errorFields['sellingPrice']
                            )}
                            <Tooltip title="Copy Selling Price">
                                <IconButton onClick={() => handleCopyToClipboard(editableProduct?.sellingPrice?.toString() || '')}>
                                    <FileCopyIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>

                        <div className="flex items-center">
                            {renderTextField('SKU', 'sku', false, errorFields['sku'])}
                            <Tooltip title="Copy SKU">
                                <IconButton onClick={() => handleCopyToClipboard((editableProduct?.sku || '').toString())}>
                                    <FileCopyIcon fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </div>

                        <div>
                            {renderSelectField('Delivery Term', 'deliveryCode', deliveryOptions, true)}
                        </div>
                    </div>
                </div>

                <div className='w-[400px] p-4 flex justify-end items-start'>
                    {filePath && id && filePath !== '-' ? (
                        <img
                            src={`${baseURL}/${filePath}`}
                            alt={`${id}`}
                            className="
                            w-full h-auto
                            max-w-[300px] max-h-[300px]
                            min-w-[200px] min-h-[200px]
                            rounded-lg shadow-md"
                        />
                    ) : (
                        <div
                            className="w-full h-64
                            flex items-center justify-center
                            bg-gray-200 rounded-lg"
                        >
                            Image not available
                        </div>
                    )}
                </div>
            </div>

            <div className='pl-4'>
                <div className='mb-6 w-full'>
                    {renderTextField('Title', 'title', true, errorFields['title'])}
                </div>

            </div>
        </>
    );
};

export default GeneralData;