import axios from "axios";

export const runUpdateInfoFromBol = async () => {

    const API = `/api/v1/bol/retailer/import`;
    const jwtToken = localStorage.getItem('jwt');
    try {
        return await axios.post(API, null, {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
        });
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};

export const processRunUpdateInfoFromBol = async () => {
    const API = `/api/v1/process/report/bol/offer/import`;
    const jwtToken = localStorage.getItem('jwt');

    try {
        return await axios.get(API, {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        console.error('Error uploading to BOL API:', error);
        throw error;
    }
};
