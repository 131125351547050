import axios from "axios";

export const saveNewImage = async (file: File): Promise<any> => {
    const API = `/api/v1/media/save/image`;
    const jwtToken = localStorage.getItem('jwt');

    const formData = new FormData();
    formData.append('multipartFile', file);

    return await axios.post(API, formData, {
        headers: {
            Authorization: `Bearer ${jwtToken}`,
            'Content-Type': 'multipart/form-data',
        },
    });
};