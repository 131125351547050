import axios from "axios";

export const deleteImage = async (imgId:number) => {

    const API = `/api/v1/image/${imgId}`;
    const jwtToken = localStorage.getItem('jwt');
    try {
        const result = await axios.delete(API,
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
            });

        return result.data;
    } catch (error) {
        console.error('ErrorMessage fetching products:', error);
        throw error;
    }
};