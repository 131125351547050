import axios from "axios";
import {ProductType} from "../types/types";

export const updateProductApi = async (productObj:ProductType ) => {

    const API = `/api/v1/product`;
    const jwtToken = localStorage.getItem('jwt');
    try {
        const result = await axios.put(API, productObj,
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
            });

        return result.data;
    } catch (error) {
        console.error('ErrorMessage fetching products:', error);
        throw error;
    }
};