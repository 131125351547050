import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {bolProductModelType, ProductType} from "../../../types/types";
import {
    TextField, Button, Tab, Snackbar,
    Alert, Checkbox, Tooltip, Typography,
    Box, Paper,
} from "@mui/material";
import ChunkFilter from "../ProductsFilters/ChunkFilter";
import {BolProductModelFields} from "../BolProductModelFields/BolProductModelFields";
import {GetProductById} from "../../../api/getProductById";
import {updateProductApi} from "../../../api/updateProductApi";
import {LoadingButton, TabContext, TabList, TabPanel} from "@mui/lab";
import ImageSorter from "../../ImageSorter/ImageSorter";
import {useStoreContainer} from "../../../store/store";
import GeneralData from "./GeneralData/GeneralData";
import AdditionalData from "./AdditionalData/AdditionalData";
import {updateProductsByFamilyKeyApi} from "../../../api/updateProductsByFamilyKeyApi";

const ProductPage = () => {
    const {productId} = useParams<{ productId: string }>();
    const {updateEditableProduct, editableProduct} = useStoreContainer(state => state);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [inheritLoading, setInheritLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [bolProductModel, setBolProductModel] = useState<bolProductModelType | null>(null);
    const [savePending, setSavePending] = useState(false);
    const [tabIndex, setTabIndex] = React.useState('1');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [size, setSize] = useState('');
    const [errorFields, setErrorFields] = useState<Partial<Record<keyof ProductType, boolean>>>({});

    useEffect(() => {
        const fetchProduct = async () => {
            if (productId) {
                try {
                    const response = await GetProductById(productId);
                    updateEditableProduct(response.data);
                } catch (err) {
                    setError((err as Error).message);
                } finally {
                    setLoading(false);
                }
            } else {
                setError('Product ID is undefined');
                setLoading(false);
            }
        };
        fetchProduct();

    }, [productId, updateEditableProduct]);

    useEffect(() => {
        if (savePending && editableProduct) {
            handleSave();
            setSavePending(false);

        }
    }, [editableProduct]);

    const handleInputChange = (field: keyof ProductType) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;

        if (field === 'mainProduct' && value && !editableProduct?.familyKey) {
            setSnackbarMessage('To select a product as main, you need to specify the Family Key.');
            setSnackbarOpen(true);
            return;
        }

        if (editableProduct) {
            updateEditableProduct({
                ...editableProduct,
                [field]: value,
            });
        }
    };

    const convertCommaToDot = (value: number | string | null | undefined): number | undefined => {
        if (value === undefined || value === null) return undefined;

        const valueStr = value.toString();
        return parseFloat(valueStr.replace(',', '.'));
    };

    const validateFields = (): boolean => {
        if (!editableProduct) return false;

        const requiredFields: (keyof ProductType)[] = ['ean', 'sellingPrice', 'title', 'deliveryCode'];
        const newErrorFields: Partial<Record<keyof ProductType, boolean>> = {};

        let isValid = true;

        requiredFields.forEach((field) => {
            if (!editableProduct?.[field]) {
                newErrorFields[field] = true;
                isValid = false;
            }
        });

        editableProduct.priceSupplier = convertCommaToDot(editableProduct?.priceSupplier) ?? 0;
        editableProduct.sellingPrice = convertCommaToDot(editableProduct?.sellingPrice) ?? 0;
        editableProduct.deliveryPrice = convertCommaToDot(editableProduct?.deliveryPrice) ?? 0;

        setErrorFields(newErrorFields);
        return isValid;
    }

    const handleSave = async () => {
        if (!validateFields()) {
            setSnackbarMessage('Please fill all required fields.');
            setSnackbarOpen(true);
            setTabIndex('1');
            return;
        }

        if (productId && editableProduct) {
            try {
                await updateProductApi(editableProduct);
                setSnackbarMessage('Product successfully updated!');
                setSnackbarOpen(true);
            } catch (err) {
                setError((err as Error).message);
            }
        }
    };

    const handleInheritProduct = async () => {
        if (editableProduct?.familyKey && productId) {
            try {
                setInheritLoading(true);
                const resp = await updateProductsByFamilyKeyApi(productId, editableProduct.familyKey);
                updateEditableProduct(resp.data);
                setSnackbarMessage('Product inheritance successful!');
                setSnackbarOpen(true);
                setInheritLoading(false);
            } catch (err) {
                setSnackbarMessage('Failed to inherit product.');
                setSnackbarOpen(true);

            } finally {
                setInheritLoading(false);
            }
        } else {
            setSnackbarMessage('Family Key is missing');
            setSnackbarOpen(true);
        }
    };

    const renderTextField = (label: string, field: keyof ProductType, required: boolean = false, error: boolean = false) => (
        <div className='flex w-full items-center' key={field}>
            <label
                className={`mb-1 w-[150px] mr-2`}>
                {label}{required &&
                <span style={{color: 'red', marginLeft: '3px'}}>*</span>}:
            </label>
            <TextField
                id={field}
                variant="outlined"
                size="small"
                value={editableProduct?.[field] !== null && editableProduct?.[field] !== undefined ? String(editableProduct[field]) : ''}
                onChange={handleInputChange(field)}
                className='min-w-[300px] w-full'
                required={required}
                error={error}
                helperText={error ? 'This field is required.' : ''}
            />
        </div>
    );

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabIndex(newValue);
    };

    const handleSaveClose = async () => {
        if (!validateFields()) {
            setSnackbarMessage('Please fill all required fields.');
            setSnackbarOpen(true);
            setTabIndex('1');
            return;
        }
        await handleSave();
        navigate(-1);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div>
            <TabContext value={tabIndex}>
                <TabList
                    onChange={handleChange}
                    aria-label="lab API tabs example"
                >
                    <Tab label="General" value="1"/>
                    <Tab label="Bol Model" value="2"/>
                </TabList>

                {(tabIndex !== '2') && (
                    <div className='mt-10 flex justify-end'>
                        <Button variant="contained" color="primary" onClick={handleSave} sx={{marginRight: '15px'}}>
                            Apply
                        </Button>

                        <Button variant="contained" onClick={handleSaveClose} sx={{marginRight: '15px'}}>
                            Save & Exit
                        </Button>

                        {(editableProduct?.mainProduct) &&
                            (<LoadingButton
                                variant="contained"
                                color="warning"
                                onClick={handleInheritProduct}
                                loading={inheritLoading}
                                loadingPosition="end"
                            >
                                {inheritLoading ? inheritLoading : 'Inherit Product'}
                            </LoadingButton>)
                        }
                        {!editableProduct?.mainProduct && (
                            <div className='flex justify-end items-center'>
                                <Tooltip
                                    title="After selecting a product as the main product, you need to save the changes
                                     for the product. After that, the option to inherit the product will become available."
                                    placement="top"
                                >
                                    <Checkbox
                                        checked={!!editableProduct?.['mainProduct' as keyof ProductType]}
                                        onChange={handleInputChange('mainProduct' as keyof ProductType)}
                                    />
                                </Tooltip>
                                <p>Is main product</p>
                            </div>
                        )}
                    </div>
                )}

                <TabPanel value="1">
                    <Box>
                        <Typography variant="h6" gutterBottom>
                            General Data
                        </Typography>
                        <Paper
                            variant="outlined"
                            style={{ padding: '16px', marginBottom: '20px', borderRadius: '16px' }}
                        >
                            <GeneralData
                                renderTextField={renderTextField}
                                errorFields={errorFields}
                                isChangeEan={editableProduct?.isChangeEan}
                            />
                        </Paper>

                        <Typography variant="h6" gutterBottom>
                            Additional Data
                        </Typography>
                        <Paper
                            variant="outlined"
                            style={{ padding: '16px', marginBottom: '20px', borderRadius: '16px' }}
                        >
                            <AdditionalData
                                renderTextField={renderTextField}
                                handleInputChange={handleInputChange}
                                setSize={setSize}
                            />
                        </Paper>

                        <Typography variant="h6" gutterBottom>
                            Images
                        </Typography>
                        <Paper
                            variant="outlined"
                            style={{ padding: '16px', borderRadius: '16px' }}
                        >
                            <ImageSorter images={editableProduct?.productImages} />
                        </Paper>
                    </Box>
                </TabPanel>

                <TabPanel value="2">
                    {productId && <ChunkFilter productId={+productId} setBolProductModel={setBolProductModel} />}
                    {bolProductModel &&
                        <BolProductModelFields
                            bolProductModel={bolProductModel}
                            images={editableProduct?.productImages}
                            size={size}
                        />}
                </TabPanel>

            </TabContext>

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{vertical: 'top', horizontal: 'center'}}
            >
                <Alert
                    onClose={handleSnackbarClose}
                    severity={snackbarMessage.includes('successful') ? 'success' : 'error'}
                    sx={{width: '100%'}}
                >
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default ProductPage;