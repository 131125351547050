import React, { FC, useEffect, useState } from 'react';
import { Autocomplete, TextField, Box } from "@mui/material";
import { getAllChunks } from "../../../bolApi/getAllChunks";
import { saveChunkForProduct } from "../../../bolApi/saveChunkForProduct";
import { bolProductModelType } from "../../../types/types";
import { getBolProductModel } from "../../../bolApi/getBolProductModel";
import { getChunkByProductId } from "../../../bolApi/GetChunkByProductId";

interface ChunkType {
    chunkId: string;
    name: string;
}

interface ApiResponse {
    chunks: ChunkType[];
}

const ChunkFilter: FC<{ productId: number, setBolProductModel: (value: bolProductModelType | null) => void }> = ({ productId, setBolProductModel }) => {
    const [chunkList, setChunkList] = useState<ChunkType[]>([]);
    const [options, setOptions] = useState<ChunkType[]>([]);
    const [selectedChunk, setSelectedChunk] = useState<ChunkType | null>(null);

    // Функция для загрузки всех чанков
    const fetchChunks = async () => {
        try {
            const response = await getAllChunks();
            const data: ApiResponse = response.data;
            setChunkList(data.chunks);
            setOptions(data.chunks);
        } catch (error) {
            console.error('Error fetching all chunks:', (error as Error).message);
        }
    };

    useEffect(() => {
        const fetchInitialData = async () => {
            try {
                const resp = await getChunkByProductId(productId);
                if (resp.status === 200 && resp.data.chunkName) {
                    const currentChunk = {
                        chunkId: resp.data.chunkId,
                        name: resp.data.chunkName,
                    };
                    setSelectedChunk(currentChunk);

                    const responseBolModel = await getBolProductModel(`${productId}`);
                    setBolProductModel(responseBolModel.data);
                } else {
                    fetchChunks();
                }
            } catch (err) {
                console.error('Error fetching product chunk:', (err as Error).message);
                fetchChunks();
            }
        };

        fetchInitialData();
    }, [productId, setBolProductModel]);

    const handleChange = async (event: any, value: ChunkType | null) => {
        setSelectedChunk(value);
        if (value) {
            try {
                await saveChunkForProduct({
                    productId: productId,
                    chunkId: value.chunkId,
                    chunkName: value.name
                });

                const responseBolModel = await getBolProductModel(`${productId}`);
                setBolProductModel(responseBolModel.data);
            } catch (err) {
                console.error('Error saving chunk for product:', (err as Error).message);
            }
        } else {
            setBolProductModel(null);
            fetchChunks();
        }
    };

    const handleInputChange = (event: any, newInputValue: string) => {
        // setSearchTerm(newInputValue);
        if (newInputValue === '') {
            setBolProductModel(null);
            setOptions(chunkList);
        } else {
            const filteredOptions = chunkList.filter(chunk =>
                chunk.name.toLowerCase().includes(newInputValue.toLowerCase())
            );
            setOptions(filteredOptions);
        }
    };

    return (
        <div>
            <Box sx={{ width: 300, marginBottom: '20px' }}>
                <Autocomplete
                    options={options}
                    value={selectedChunk}
                    getOptionLabel={(option) => option.name}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Choose Bol category"
                            variant="standard"
                        />
                    )}
                    clearOnEscape
                />
            </Box>
        </div>
    );
};

export default ChunkFilter;