import axios from 'axios';

export const updateProductsByFamilyKeyApi = async (productId: string, familyKey:string) => {
    const API = `/api/v1/product/family?mainProductId=${productId}&familyKey=${familyKey}`;
    const jwtToken = localStorage.getItem('jwt');

    try {
        return await axios.put(API, null, {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        console.error('Error uploading to BOL API:', error);
        throw error;
    }
};