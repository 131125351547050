import axios from 'axios';

interface ChunkForProductType {
    productId: number;
    chunkId : string;
    chunkName: string;
}
export const saveChunkForProduct = async (obj:ChunkForProductType) => {
    const API = `/api/v1/bol/data/model/chunk/save`;
    const jwtToken = localStorage.getItem('jwt');

    try {
        return await axios.post(API, obj, {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        console.error('Error uploading to BOL API:', error);
        throw error;
    }
};