import axios from "axios";

export const logsApi = async (quantity:number) => {

    const API = `/api/v1/history/feed?page=0&size=${quantity}&sort=dateTimeOperation,desc`;
    const jwtToken = localStorage.getItem('jwt');
    try {
        const result = await axios.get(API,
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
            });

        return result.data;
    } catch (error) {
        console.error('ErrorMessage fetching products:', error);
        throw error;
    }
};