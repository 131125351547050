import axios from 'axios';
import qs from 'qs';
interface GetFilteredProductsParams {
    productQuantity: number;
    supplierName: string;
    page: number;
    brand?: string;
    search?: string;
    passedModeration?: string;
    hasDescription?: string;
    buyBlock?: string;
    priceFrom?: string;
    priceTo?: string;
    categoryId?: string;
    dateFrom?: string;
    dateTo?: string;
    sort?: string;
    numberOfImages?:string;
    familyKey?:string;
    isMainProduct?:string;
    isExportOnBol?:string;
    isChangeEan?:string;
    stock?:string;
}

export const getFilteredProducts = async ({
  productQuantity,
  supplierName,
  page,
  brand = '',
  search = '',
  passedModeration = '',
  hasDescription = '',
  buyBlock = '',
  priceFrom = '',
  priceTo = '',
  categoryId = '',
  dateFrom = '',
  dateTo = '',
  sort = '',
  numberOfImages='',
  familyKey='',
  isMainProduct='',
  isExportOnBol='',
  isChangeEan='',
                                              stock='',
}: GetFilteredProductsParams) => {

    const queryParams = {
        size: productQuantity,
        page,
        supplierName,
        brand,
        keyword: search,
        passedModeration,
        hasDescription,
        buyBlock,
        priceFrom,
        priceTo,
        categoryId,
        dateFrom,
        dateTo,
        sort,
        numberOfImages,
        familyKey,
        isMainProduct,
        isExportOnBol,
        isChangeEan,
        stock
    };

    const API = `/api/v1/bol/product/filter?${qs.stringify(queryParams)}`;
    const jwtToken = localStorage.getItem('jwt');
    try {
        const result = await axios.get(API,
            {
                headers: {
                    Authorization: `Bearer ${jwtToken}`,
                },
            });
        return result.data;
    } catch (error: any) {
        if (error.response) {
            console.error('Server error:', error.response.data);
        } else if (error.request) {
            console.error('Network error:', error.request);
        } else {
            console.error('ErrorMessage:', error.message);
        }
        throw error;
    }
};