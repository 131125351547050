import axios from "axios";

export const signIn = async (username: string, password: string) => {
    const API = `/auth/login`;

    try {
        return await axios.post(API, {username, password});
    } catch (error) {
        console.error('ErrorMessage signing in:', error);
        throw error;
    }
};