import axios from 'axios';

export const getUploadReportApi = async (array: number[] | [], additionalData?:string) => {
    const API = `/api/v1/bol/report/upload${additionalData}`;
    const jwtToken = localStorage.getItem('jwt');

    try {
        return await axios.post(API, { productIds: array }, {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
                'Content-Type': 'application/json',
            },
        });
    } catch (error) {
        console.error('Error uploading to BOL API:', error);
        throw error;
    }
};