import React, { useState } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ProductImage, ProductType } from '../../types/types';
import SaveImage from '../saveImage/SaveImage';
import { deleteImage } from '../../api/deleteImageApi';
import { useStoreContainer } from '../../store/store';
import { updateProductApi } from '../../api/updateProductApi';

const ImageSorter = ({ images }: { images: ProductImage[] | undefined }) => {
    const {
        editableProduct,
        updateEditableProduct,
    } = useStoreContainer(state => state);

    const [open, setOpen] = useState(false);
    const [imageToDelete, setImageToDelete] = useState<{ imgId: number, imgPath: string } | null>(null);
    const baseURL = `${window.location.protocol}//${window.location.host}`;

    const handleDeleteImageClick = (imgId: number, imgPath: string) => {
        setImageToDelete({ imgId, imgPath });
        setOpen(true);
    };

    const handleConfirmDelete = async () => {
        if (imageToDelete && editableProduct) {
            try {
                await deleteImage(imageToDelete.imgId);

                const updatedImages = editableProduct.productImages.filter(
                    (img) => img.id !== imageToDelete.imgId
                );

                const updatedProduct = {
                    ...editableProduct,
                    productImages: updatedImages,
                };

                updateEditableProduct(updatedProduct);

                await handleSave(updatedProduct);
            } catch (error) {
                console.error('Error deleting image:', error);
            } finally {
                setOpen(false);
                setImageToDelete(null);
            }
        }
    };

    const handleCancelDelete = () => {
        setOpen(false);
        setImageToDelete(null);
    };

    const handleSaveNewImage = async (filePaths: string[]) => {
        if (editableProduct) {
            const newImages: ProductImage[] = filePaths.map(filePath => ({
                filePath,
                id: Date.now() + Math.floor(Math.random() * 1000000),
                isMainImage: false,
                name: '',
                altImage: '',
            }));

            const updatedProduct = {
                ...editableProduct,
                productImages: [...(editableProduct.productImages || []), ...newImages],
            };

            updateEditableProduct(updatedProduct);

            await handleSave(updatedProduct);
        }
    };

    const handleSave = async (productToSave: ProductType) => {
        if (productToSave) {
            try {
                await updateProductApi(productToSave);
            } catch (err) {
                console.error('Error while saving:', (err as Error).message);
            }
        } else {
            console.error('Product to save is not defined.');
        }
    };

    const handleImageChange = (index: number, field: keyof ProductImage, value: any) => {
        if (editableProduct) {
            const updatedImages = [...editableProduct.productImages];
            updatedImages[index] = {
                ...updatedImages[index],
                [field]: value
            };

            const updatedProduct = {
                ...editableProduct,
                productImages: updatedImages,
            };

            updateEditableProduct(updatedProduct);
        }
    };

    const handlePositionChange = (imageId: number, newPosition: number) => {
        if (editableProduct) {
            const updatedImages = [...editableProduct.productImages];
            const currentImageIndex = updatedImages.findIndex(img => img.id === imageId);

            if (currentImageIndex !== -1) {
                const [movedImage] = updatedImages.splice(currentImageIndex, 1);
                updatedImages.splice(newPosition, 0, movedImage);

                const updatedProduct = {
                    ...editableProduct,
                    productImages: updatedImages,
                };

                updateEditableProduct(updatedProduct);
            }
        }
    };

    return (
        <div className="flex flex-col gap-4 mb-6">
            <SaveImage handleSaveNewImage={handleSaveNewImage} />

            {images?.map((imgItem, index) => (
                <div key={imgItem.id} className="flex items-start gap-4 p-4 border rounded-lg shadow">
                    <img
                        src={`${baseURL}/${imgItem.filePath}`}
                        alt={imgItem.altImage || 'Image'}
                        className="h-[150px] w-auto rounded-lg shadow-sm"
                    />
                    <div className="flex flex-col gap-3 w-full">
                        <TextField
                            label="Name"
                            value={imgItem.name || ''}
                            onChange={(e) => handleImageChange(index, 'name', e.target.value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                        <TextField
                            label="Alt Image"
                            value={imgItem.altImage || ''}
                            onChange={(e) => handleImageChange(index, 'altImage', e.target.value)}
                            size="small"
                            variant="outlined"
                            fullWidth
                        />
                        <FormControl size="small" variant="outlined" fullWidth>
                            <InputLabel>Position</InputLabel>
                            <Select
                                value={index}
                                onChange={(e) => handlePositionChange(imgItem.id, e.target.value as number)}
                                label="Position"
                            >
                                {editableProduct?.productImages.map((_, positionIndex) => (
                                    <MenuItem key={positionIndex} value={positionIndex}>
                                        {positionIndex + 1}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div className="flex justify-end mt-2">
                            <IconButton
                                color="secondary"
                                onClick={() => handleDeleteImageClick(imgItem.id, imgItem.filePath)}
                            >
                                <DeleteIcon />
                            </IconButton>
                        </div>
                    </div>
                </div>
            ))}

            <Dialog open={open} onClose={handleCancelDelete}>
                <DialogTitle>Confirm Deletion</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this image?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelDelete} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} color="secondary">
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ImageSorter;