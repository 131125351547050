import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import {MainListItems } from './listItems';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {useLocation, useNavigate} from 'react-router-dom';
import AppRouter from "../Router/AppRouter";
import {AppBar } from "../helpers/Item";

const defaultTheme = createTheme();

export default function Dashboard() {
  const { pathname } = useLocation();
  const pageName = pathname.split('/')[1];
  const navigate = useNavigate();


  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex', flexDirection: 'column', boxSizing:'border-box', height: '100vh'}}>
        <CssBaseline />

        <AppBar sx={{position: 'sticky'}}>

          <Toolbar sx={{maxWidth:'1200px', width:'100%', margin: 'auto'}} className='test'>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1, textTransform: "capitalize" }}
            >
              {pageName}
            </Typography>

            <IconButton color="inherit" onClick={() => navigate('/login')}>
                <AccountCircleIcon/>
            </IconButton>
          </Toolbar>

            {/*<Divider sx={{ my: 1, color: 'white' }} />*/}

            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                maxWidth:'1200px', width:'100%', margin: 'auto'
            }}
            >
                <MainListItems />
            </Box>
        </AppBar>

        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >

          {/*<Toolbar />*/}

          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {/*<Grid container spacing={3}>*/}
            {/*</Grid>*/}
            <AppRouter/>

          </Container>
        </Box>

      </Box>
    </ThemeProvider>
  );
}
