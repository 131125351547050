import React, {useEffect} from 'react';
import './App.css';
import Dashboard from "./dashboard/Dashboard";
import {useLocation, useNavigate} from "react-router-dom";

function App() {

    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        if(localStorage.getItem('jwt') === null) navigate('/login');
    }, [pathname])

    useEffect(() => {

        const expiredAccessToken = localStorage.getItem('expiredAccessToken');
        const currentTime = Date.now();

        if(currentTime > Number(expiredAccessToken)) {
            localStorage.removeItem('jwt');
            localStorage.removeItem('expiredAccessToken');
            navigate('/login')
        }
    }, [pathname]);

  return (
    <div>
      <Dashboard/>
    </div>
  );
}

export default App;
