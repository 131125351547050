import axios from "axios";

export const importOrdersFromBolApi = async () => {
    const API = `/api/v1/bol/order/import`;
    const jwtToken = localStorage.getItem('jwt');
    try {
        return await axios.post(API, null, {
            headers: {
                Authorization: `Bearer ${jwtToken}`,
            },
        });
    } catch (error) {
        console.error('Error fetching products:', error);
        throw error;
    }
};